import React, { useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { StaticImage } from "gatsby-plugin-image"
import media from "css-in-js-media"
import { Helmet } from "react-helmet"
//@ts-ignore
import ImgPat from "../../images/features/inbox/pat.jpg"
//@ts-ignore
import ImgJanet from "../../images/features/inbox/janet.jpg"
import { Link } from "gatsby"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"

const ScriptAnimation = keyframes`
  0% {
    transform: rotateY(0) perspective(700px) scale(1) translate(0, 0);
  }
  100% {
    transform: rotateY(-20deg) perspective(700px) scale(0.85) translate(150rem, 0px);
  }
`

const Container = styled.div`
    section.hero {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 120rem 0 200rem;
        flex-direction: row-reverse;

        ${media("<tablet")} {
            padding-bottom: 80rem;
        }

        ${media(">phone")} {
            flex-direction: column-reverse;

            > .left {
                text-align: center;
                align-items: center;
            }
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 600rem;

            .pre-header {
                display: flex;
                align-items: center;
                gap: 10rem;
                font-size: 14rem;
                color: ${({ theme }) => theme.text.lighter};
                font-family: "Gilroy-Regular", sans-serif;
                margin-bottom: 10rem;
            }

            h1 {
                font-size: 55rem;
                font-family: "Gilroy-Bold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-weight: unset;
                margin: 0;

                span {
                    background: -webkit-linear-gradient(#60a5fa, #818cf8);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            h2 {
                font-weight: normal;
                font-size: 20rem;
                color: rgb(76, 87, 115);
                line-height: 35rem;
                max-width: 490rem;
            }

            ${media("<phone")} {
                > h1:not(.desktop) {
                    font-size: 35rem;
                }

                > h2:not(.desktop) {
                    font-size: 20rem;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 30rem;
            position: relative;
            perspective: 2000rem;
            perspective-origin: 50% 50%;
            overflow: visible;

            ${media("<tablet")} {
                display: none;
            }

            .back {
                position: absolute;
                top: 30rem;
                bottom: 30rem;
                left: 0;
                right: 0;
                margin: auto;
                width: 200rem;
                height: 200rem;
                opacity: 0;
                background: #bfdbfe;
                border-radius: 90%;
                transform-origin: center;
            }

            .anim-container {
                display: flex;
                perspective: 1500rem;
                overflow: visible;
                width: 100%;
                justify-content: center;
                transform-origin: center;
                height: 160rem;

                > div {
                    display: flex;
                    max-width: 600rem;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    > div {
                        display: flex;
                        width: 100%;
                        perspective: 2000rem;
                        transform-style: flat;
                        transform-origin: center;

                        > div {
                            display: flex;
                            flex-direction: column;
                            gap: 10rem;
                            background: #fff;
                            padding: 20rem 20rem;
                            color: ${({ theme }) => theme.text.light};
                            border-radius: 10rem;
                            box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
                            position: absolute;
                            width: 270rem;
                            text-rendering: optimizeLegibility;

                            &.primary {
                                box-shadow: rgba(50, 50, 93, 0.25) 0px 50px
                                        100px -20px,
                                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
                                z-index: 20;
                                margin: auto;
                                left: 0;
                                right: 0;
                            }

                            &:not(.primary) {
                                filter: blur(1rem) grayscale(0.2);
                                transform: scale(0.8);

                                left: 0;

                                &:last-of-type {
                                    transform: scale(0.7);
                                    filter: blur(2rem) grayscale(0.3);
                                    left: unset;
                                    right: 0;
                                }
                            }

                            > div {
                                display: flex;
                                align-items: center;
                                gap: 10rem;

                                .name {
                                    color: ${({ theme }) => theme.text.normal};
                                    font-family: "Gilroy-Medium", sans-serif;
                                }

                                .status {
                                    padding: 5rem 10rem;
                                    border-radius: 8rem;
                                    font-size: 12rem;
                                    font-weight: 600;
                                    transition: all ease-in-out 250ms;
                                    text-align: center;

                                    &.active {
                                        background: #cbf4c9;
                                        color: #0e6245;
                                    }

                                    &.spam {
                                        background: #fecdd3;
                                        color: #be123c;
                                    }

                                    &.ooh {
                                        background: #bfdbfe;
                                        color: #1d4ed8;
                                    }

                                    &.forwarding {
                                        background: #fef9c3;
                                        color: #a16207;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.quick-feats {
        display: flex;
        align-items: center;
        margin: 0 -20rem;
        padding: 70rem 20rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

        > div {
            display: flex;
            gap: 90rem;

            ${media("<desktop")} {
                > div {
                    min-width: calc(50% - 40rem);
                }

                flex-wrap: wrap;
                gap: 50rem;
            }

            ${media("<=phone")} {
                flex-direction: column;

                > div:nth-of-type(even) {
                    min-width: unset;
                    align-items: flex-end;
                    text-align: right;
                }
            }

            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 15rem;

                svg {
                    width: 50rem;
                    height: 50rem;
                }

                .title {
                    font-size: 20rem;
                    color: ${({ theme }) => theme.text.dark};
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                .description {
                    color: ${({ theme }) => theme.text.normal};
                    font-size: 15rem;
                    line-height: 30rem;
                }
            }
        }
    }

    section.start-right {
        margin: 0 -20rem;
        padding: 100rem 20rem 100rem;
        border-top: 1px solid ${({ theme }) => theme.borders.light};

        .leader {
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 100%;
                margin: auto;
                gap: 20rem;
                text-align: center;

                .pretitle {
                    color: ${({ theme }) => theme.primary};
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 20rem;
                }

                h3.title {
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 50rem;
                    color: ${({ theme }) => theme.text.dark};
                }

                .subtitle {
                    font-size: 18rem;
                    line-height: 30rem;
                    color: ${({ theme }) => theme.text.normal};
                    max-width: 400rem;
                }
            }
        }

        .content {
            border-radius: 10rem;
            padding: 30rem 35rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            > .tile {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 50rem 0;
                max-width: 1000rem;
                gap: 20rem;

                &:nth-of-type(even) {
                    flex-direction: row-reverse;
                }

                .left {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    .icon {
                        width: 30rem;
                        height: 30rem;
                        background: #eff6ff;
                        border-radius: 90%;

                        svg {
                            width: 30rem;
                            height: 30rem;
                            transform: scale(0.95);
                        }
                    }

                    h4 {
                        font-family: "Gilroy-Black", sans-serif;
                        font-size: 30rem;
                        color: ${({ theme }) => theme.primary};
                        margin: 0 0 10rem;
                        background: -webkit-linear-gradient(#60a5fa, #818cf8);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    h5 {
                        margin: 10rem 0;
                        font-size: 16rem;
                        letter-spacing: 2rem;
                        font-weight: 600;
                        color: #60a5fa;
                        text-transform: uppercase;
                    }

                    p {
                        font-size: 16rem;
                        line-height: 28rem;
                        margin: 0;
                        color: ${({ theme }) => theme.text.light};
                    }
                }

                > .right {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .image {
                        position: relative;
                        z-index: 50;
                        border-radius: 15rem;
                        max-width: 300rem;

                        &.shadow {
                            box-shadow: #eff6ff 0px 50px 100px -20px,
                                #c7d2fe 0px 30px 60px -30px;
                        }
                    }
                }

                ${media("<desktop")} {
                    &:not(.ms) {
                        flex-direction: column;
                        text-align: center;
                        align-items: center;
                        gap: 50px;

                        .left {
                            max-width: 500rem;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
`

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const FeaturesTeamSms = ({ data, location }) => {
    const isMounted = useRef(true)
    const animationFrameRef = useRef<number>(0)
    const statusTickerRef = useRef<number>(0)
    const statusElementsRef = useRef<Array<HTMLDivElement>>([])
    const card1Ref = useRef<HTMLDivElement>(null)
    const card2Ref = useRef<HTMLDivElement>(null)
    const card3Ref = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<Array<{ x: number; y: number; height: number }>>([
        { x: 5, y: 1, height: 0 },
        { x: 2, y: 15, height: -50 },
        { x: 8, y: 35, height: 200 },
    ])

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const new1X = Math.cos((valuesRef.current[0].x += 0.009)) * 10
            const new1Y = Math.cos((valuesRef.current[0].y += 0.003)) * 8
            const new1Height =
                Math.cos((valuesRef.current[0].height += 0.007)) * 12

            const new2X =
                -Math.abs(Math.cos((valuesRef.current[1].x += 0.009)) * 2) - 5
            const new2Y = Math.cos((valuesRef.current[1].y += 0.008)) * 9
            const new2Height =
                Math.cos((valuesRef.current[1].height += 0.03)) * 5

            const new3X = Math.cos((valuesRef.current[2].x += 0.008)) * 3
            const new3Y = Math.cos((valuesRef.current[2].y += 0.03)) * 12
            const new3Height =
                Math.cos((valuesRef.current[2].height += 0.02)) * 6

            card1Ref.current.style.transform = `scale(0.8) rotateX(${new1X}deg) rotateY(${new1Y}deg) translateY(${new1Height}rem)`
            card2Ref.current.style.transform = `rotateX(${new2X}deg) rotateY(${new2Y}deg) translateY(${new2Height}rem)`
            card3Ref.current.style.transform = `scale(0.6) rotateX(${new3X}deg) rotateY(${new3Y}deg) translateY(${new3Height}rem)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        const statusChange = () => {
            if (!isMounted.current) {
                return
            }

            if (statusElementsRef.current.length === 0) {
                statusElementsRef.current = Array.from(
                    document.querySelectorAll('[data-anim-role="status"]')
                )
            }

            const element =
                statusElementsRef.current[
                    Math.floor(Math.random() * statusElementsRef.current.length)
                ]
            const newStatus = Math.floor(Math.random() * 6)

            switch (newStatus) {
                case 0:
                    element.innerHTML = "Forwarding"
                    element.classList.remove("active", "ooh", "spam")
                    element.classList.add("forwarding")
                    break
                case 1:
                    element.innerHTML = "Out of hours"
                    element.classList.remove("active", "spam", "forwarding")
                    element.classList.add("ooh")
                    break
                case 2:
                    element.innerHTML = "Marked as spam"
                    element.classList.remove("active", "ooh", "forwarding")
                    element.classList.add("spam")
                    break
                default:
                    element.innerHTML = "Active"
                    element.classList.remove("ooh", "spam", "forwarding")
                    element.classList.add("active")
                    break
            }

            // Schedule next tick
            const waitTime = Math.max(Math.random() * 8000, 2000)

            setTimeout(statusChange, waitTime)
        }

        if (animationFrameRef.current === 0) {
            animationFrameRef.current = requestAnimationFrame(paint)

            // Schedule next tick
            const waitTime = Math.max(Math.random() * 4000, 500)

            setTimeout(statusChange, waitTime)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Page
            constrainedHeader
            constrainedFooter
            constrainedCta
            location={location}
        >
            <Helmet>
                <title>Phone numbers</title>
            </Helmet>
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <h1>
                            We supply <span>local</span>, <span>mobile</span>,
                            and <span>toll free</span> in 90+ countries.
                        </h1>
                        <h2>
                            Make and receive calls, setup opening hours, and
                            forward right within 23shout.
                        </h2>
                        <div>
                            <a href={"//app.23shout.com"} tabIndex={-1}>
                                <FlatButton color={"primary"} size={"larger"}>
                                    Get started for free <Chevron />
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                    <div className={"right"}>
                        <div className={"anim-container"}>
                            <div>
                                <div>
                                    <div ref={card1Ref}>
                                        <div>
                                            <StaticImage
                                                src={
                                                    "../../images/features/phone-numbers/usa.png"
                                                }
                                                alt={"United states flag"}
                                                layout={"fixed"}
                                                height={30}
                                                placeholder={"none"}
                                            />
                                            <div className={"name"}>
                                                US Customer Support
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className={"status active"}
                                                data-anim-role={"status"}
                                            >
                                                Active
                                            </div>
                                            <div className={"number"}>
                                                706-752-7095
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"primary"} ref={card2Ref}>
                                        <div>
                                            <StaticImage
                                                src={
                                                    "../../images/features/phone-numbers/aus.png"
                                                }
                                                alt={"Australian flag"}
                                                layout={"fixed"}
                                                height={30}
                                                placeholder={"none"}
                                            />
                                            <div className={"name"}>
                                                Australia support
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className={"status active"}
                                                data-anim-role={"status"}
                                            >
                                                Active
                                            </div>
                                            <div className={"number"}>
                                                1800 951 343
                                            </div>
                                        </div>
                                    </div>

                                    <div ref={card3Ref}>
                                        <div>
                                            <StaticImage
                                                src={
                                                    "../../images/features/phone-numbers/aus.png"
                                                }
                                                alt={"Australian flag"}
                                                layout={"fixed"}
                                                height={30}
                                                placeholder={"none"}
                                            />
                                            <div className={"name"}>
                                                Sales outbound
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className={"status active"}
                                                data-anim-role={"status"}
                                            >
                                                Active
                                            </div>
                                            <div className={"number"}>
                                                0480 032 023
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"quick-feats"}>
                    <div className={"pageConstrained"}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <rect fill="none" height="24" width="24" />
                                <path d="M19.3,16.9c0.58-1.01,0.95-2.23,0.51-3.65c-0.53-1.72-2.04-3.05-3.84-3.22c-2.87-0.28-5.23,2.07-4.95,4.95 c0.18,1.79,1.5,3.31,3.22,3.84c1.43,0.44,2.64,0.07,3.65-0.51l2.5,2.5c0.39,0.39,1.01,0.39,1.4,0l0,0c0.39-0.39,0.39-1.01,0-1.4 L19.3,16.9z M15.5,17c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S16.9,17,15.5,17z M12,20v2C6.48,22,2,17.52,2,12 C2,6.48,6.48,2,12,2c4.84,0,8.87,3.44,9.8,8h-2.07c-0.64-2.46-2.4-4.47-4.73-5.41V5c0,1.1-0.9,2-2,2h-2v2c0,0.55-0.45,1-1,1H8v2h2v3 H9l-4.79-4.79C4.08,10.79,4,11.38,4,12C4,16.41,7.59,20,12,20z" />
                            </svg>
                            <div className={"title"}>Worldwide inventory</div>
                            <div className={"description"}>
                                Our phone number library supports 90+ countries,
                                including Australia, the United States, and New
                                Zealand for instant provisioning.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <path d="M0,0h24v24H0V0z" fill="none" />
                                </g>
                                <g>
                                    <g>
                                        <path d="M11.29,8.28l-3.01,3.01c-0.39,0.39-0.39,1.02,0,1.41l3.01,3.01c0.39,0.39,1.02,0.39,1.41,0l3.01-3.01 c0.39-0.39,0.39-1.02,0-1.41l-3.01-3.01C12.32,7.89,11.68,7.89,11.29,8.28z" />
                                        <path d="M10.59,2.59l-8.01,8c-0.78,0.78-0.78,2.05,0,2.83l8.01,8c0.78,0.78,2.05,0.78,2.83,0L18,16.82V13.2l-6,6L4.79,12L12,4.79 l6,6V7.17l-4.58-4.58C12.64,1.8,11.37,1.8,10.59,2.59z" />
                                        <path d="M20,11v6c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-6c0-0.55-0.45-1-1-1h0C20.45,10,20,10.45,20,11z" />
                                        <circle cx="21" cy="21" r="1" />
                                    </g>
                                </g>
                            </svg>
                            <div className={"title"}>Spam detection</div>
                            <div className={"description"}>
                                Our system will notify you if one or more of
                                your numbers has been reported as spam, likely
                                preventing pick ups for outbound calls.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <path d="M0,0h24v24H0V0z" fill="none" />
                                </g>
                                <g>
                                    <path d="M21.41,11.41l-8.83-8.83C12.21,2.21,11.7,2,11.17,2H4C2.9,2,2,2.9,2,4v7.17c0,0.53,0.21,1.04,0.59,1.41l8.83,8.83 c0.78,0.78,2.05,0.78,2.83,0l7.17-7.17C22.2,13.46,22.2,12.2,21.41,11.41z M6.5,8C5.67,8,5,7.33,5,6.5S5.67,5,6.5,5S8,5.67,8,6.5 S7.33,8,6.5,8z" />
                                </g>
                            </svg>
                            <div className={"title"}>Flexible pricing</div>
                            <div className={"description"}>
                                Numbers bill monthly with no lock ins, giving
                                you freedom from long term contracts.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <rect fill="none" height="24" width="24" />
                                <g>
                                    <path d="M18.04,7.99l-0.63-1.4l-1.4-0.63c-0.39-0.18-0.39-0.73,0-0.91l1.4-0.63l0.63-1.4c0.18-0.39,0.73-0.39,0.91,0l0.63,1.4 l1.4,0.63c0.39,0.18,0.39,0.73,0,0.91l-1.4,0.63l-0.63,1.4C18.78,8.38,18.22,8.38,18.04,7.99z M21.28,12.72L20.96,12 c-0.18-0.39-0.73-0.39-0.91,0l-0.32,0.72L19,13.04c-0.39,0.18-0.39,0.73,0,0.91l0.72,0.32L20.04,15c0.18,0.39,0.73,0.39,0.91,0 l0.32-0.72L22,13.96c0.39-0.18,0.39-0.73,0-0.91L21.28,12.72z M16.24,14.37l1.23,0.93c0.4,0.3,0.51,0.86,0.26,1.3l-1.62,2.8 c-0.25,0.44-0.79,0.62-1.25,0.42l-1.43-0.6c-0.2,0.13-0.42,0.26-0.64,0.37l-0.19,1.54c-0.06,0.5-0.49,0.88-0.99,0.88H8.38 c-0.5,0-0.93-0.38-0.99-0.88L7.2,19.59c-0.22-0.11-0.43-0.23-0.64-0.37l-1.43,0.6c-0.46,0.2-1,0.02-1.25-0.42l-1.62-2.8 c-0.25-0.44-0.14-0.99,0.26-1.3l1.23-0.93C3.75,14.25,3.75,14.12,3.75,14s0-0.25,0.01-0.37L2.53,12.7c-0.4-0.3-0.51-0.86-0.26-1.3 l1.62-2.8c0.25-0.44,0.79-0.62,1.25-0.42l1.43,0.6c0.2-0.13,0.42-0.26,0.64-0.37l0.19-1.54C7.45,6.38,7.88,6,8.38,6h3.23 c0.5,0,0.93,0.38,0.99,0.88l0.19,1.54c0.22,0.11,0.43,0.23,0.64,0.37l1.43-0.6c0.46-0.2,1-0.02,1.25,0.42l1.62,2.8 c0.25,0.44,0.14,0.99-0.26,1.3l-1.23,0.93c0.01,0.12,0.01,0.24,0.01,0.37S16.25,14.25,16.24,14.37z M13,14c0-1.66-1.34-3-3-3 s-3,1.34-3,3s1.34,3,3,3S13,15.66,13,14z" />
                                </g>
                            </svg>
                            <div className={"title"}>Customise behaviour</div>
                            <div className={"description"}>
                                Setup opening hours, pickup messages, hold
                                music, and much more with our inbound queues.
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"start-right"}>
                    <div className={"pageConstrained"}>
                        <div className={"leader"}>
                            <div className={"title"}>
                                <div className={"pretitle"}></div>
                                <h3 className={"title"}>
                                    Your answer to phone numbers
                                </h3>
                                <div className={"subtitle"}>
                                    With our global marketplace and number
                                    porting, this side of your business
                                    operations is solved.
                                </div>
                            </div>
                        </div>

                        <div className={"content"}>
                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Spam free</h5>
                                    <h4>
                                        All numbers are verified as not being
                                        marked for spam
                                    </h4>
                                    <p>
                                        Our custom built service will ensure no
                                        numbers are rented out to our customers
                                        while marked for spam, ensuring your
                                        pickup rates remain high.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/phone-numbers/spam-free.png"
                                        }
                                        height={350}
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>BYO</h5>
                                    <h4>
                                        Port your existing number over to unlock
                                        new powers
                                    </h4>
                                    <p style={{ marginBottom: "10rem" }}>
                                        Bringing an existing number gives you
                                        the full feature set of 23shout without
                                        the stationary reprint. Porting is
                                        simple, and usually takes less than 24
                                        hours with no down time.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/assign.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Analytical</h5>
                                    <h4>
                                        Built in analytics to monitor
                                        bidirectional call rates
                                    </h4>
                                    <p>
                                        Integrated charting tools show you total
                                        number utilisation across your team,
                                        with hourly breakdowns for calls going
                                        both ways.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/phone-numbers/cpm.png"
                                        }
                                        height={350}
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Personalised</h5>
                                    <h4>
                                        Assign numbers to be used exclusively
                                        for certain agents
                                    </h4>
                                    <p>
                                        It's simple to add overrides to 23shout
                                        so specific team members will use a
                                        certain number for different calls
                                        instead of the default number assigned
                                        to the queue, unlocking new rapport
                                        building tools in your funnels.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/abstract.png"
                                        }
                                        height={350}
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <h5>Conversations</h5>
                                    <h4>
                                        All numbers work with our SMS
                                        Conversations
                                    </h4>
                                    <p>
                                        It doesn't end at calling,{" "}
                                        <Link to={"/features/team-sms"}>
                                            <TextButton color={"primary"}>
                                                our Conversations
                                            </TextButton>
                                        </Link>{" "}
                                        feature also breathes new life into your
                                        SMS capable numbers, and all the good
                                        stuff that brings.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/inbox/inbox.png"
                                        }
                                        height={350}
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"fixed"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"leader"} style={{ marginTop: "100px" }}>
                        <div className={"title"}>
                            <div className={"pretitle"}>
                                More ways we're different
                            </div>
                        </div>
                    </div>
                    <section className={"quick-feats"}>
                        <div className={"pageConstrained"}>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                            x="0"
                                        />
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                        </g>
                                    </g>
                                </svg>
                                <div className={"title"}>Beautiful calling</div>
                                <div className={"description"}>
                                    We're not that solution that runs on Windows
                                    95, we're designed from the ground up to
                                    fill the void for a modern contact centre.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <rect fill="none" height="24" width="24" />
                                    <g>
                                        <path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15c0.11-0.26,0.15-0.56,0.09-0.87C17.84,2.49,17.14,1.99,16.4,2 c-1.59,0.03-3,0.81-3.9,2l-5,0C4.46,4,2,6.46,2,9.5c0,2.25,1.37,7.48,2.08,10.04C4.32,20.4,5.11,21,6.01,21L8,21c1.1,0,2-0.9,2-2v0 h2v0c0,1.1,0.9,2,2,2l2.01,0c0.88,0,1.66-0.58,1.92-1.43l1.25-4.16l2.14-0.72c0.41-0.14,0.68-0.52,0.68-0.95V8.5c0-0.55-0.45-1-1-1 H19.83z M12,9H9C8.45,9,8,8.55,8,8v0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1v0C13,8.55,12.55,9,12,9z M16,11c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    No lock in contracts
                                </div>
                                <div className={"description"}>
                                    There's no tricks, we're confident you'll
                                    like 23shout. Subscriptions are billed
                                    monthly, and if you're not happy we'll even
                                    pro-rata your time back to you.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill={"url(#brandGradient)"}
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                    </g>
                                    <g>
                                        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z" />
                                    </g>
                                </svg>
                                <div className={"title"}>Here to help</div>
                                <div className={"description"}>
                                    You're not left to figure it out, let's
                                    arrange a call anytime to help your team
                                    succeed with 23shout. No request is too
                                    large or small.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <path d="M9.78,11.16l-1.42,1.42c-0.68-0.69-1.34-1.58-1.79-2.94l1.94-0.49C8.83,10.04,9.28,10.65,9.78,11.16z M10.15,5.15 L7.35,2.35c-0.2-0.2-0.51-0.2-0.71,0L3.85,5.15C3.54,5.46,3.76,6,4.21,6h1.81C6.04,6.81,6.1,7.54,6.21,8.17l1.94-0.49 C8.08,7.2,8.03,6.63,8.02,6h1.78C10.24,6,10.46,5.46,10.15,5.15z M20.15,5.15l-2.79-2.79c-0.2-0.2-0.51-0.2-0.71,0l-2.79,2.79 C13.54,5.46,13.76,6,14.21,6h1.78c-0.1,3.68-1.28,4.75-2.54,5.88c-0.5,0.44-1.01,0.92-1.45,1.55c-0.34-0.49-0.73-0.88-1.13-1.24 L9.46,13.6C10.39,14.45,11,15.14,11,17c0,0,0,0,0,0h0v4c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-4c0,0,0,0,0,0 c0-2.02,0.71-2.66,1.79-3.63c1.38-1.24,3.08-2.78,3.2-7.37h1.8C20.24,6,20.46,5.46,20.15,5.15z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    Keep your data clean
                                </div>
                                <div className={"description"}>
                                    Setup call <strong>Outcomes</strong> with
                                    automated actions. Automatically schedule no
                                    answers to be called again in the future, or
                                    add numbers to Do Not Call lists.
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeaturesTeamSms
